const permissions = {
  PATIENTFLOW_TICKET_CREATE: 'PATIENTFLOW_TICKET_CREATE',
  PATIENTFLOW_TICKET_WRITE: 'PATIENTFLOW_TICKET_WRITE',
  PATIENTFLOW_TICKET_READ: 'PATIENTFLOW_TICKET_VIEW',
  PATIENTFLOW_TICKET_ARCHIVE: 'PATIENTFLOW_TICKET_DELETE',
  PATIENTFLOW_TICKET_SEARCH: 'PATIENTFLOW_TICKET_SEARCH',
  PATIENTFLOW_CHECKIN: 'PATIENTFLOW_CHECKIN',
  PATIENTFLOW_DASHBOARD: 'PATIENTFLOW_DASHBOARD',

  BOOKING_VIEW: 'BOOKING_VIEW',
  BOOKING_CREATE: 'BOOKING_CREATE',
  BOOKING_SEARCH: 'BOOKING_SEARCH',
  BOOKING_WRITE: 'BOOKING_WRITE',
  BOOKING_ARCHIVE: 'BOOKING_DELETE',
  BOOKING_CANCEL: 'BOOKING_CANCEL',

  LAB_VIEW: 'LAB_VIEW',
  LAB_CREATE: 'LAB_CREATE',
  LAB_DELETE: 'LAB_DELETE',
  LAB_CANCEL: 'LAB_CANCEL',
  LAB_WRITE: 'LAB_WRITE',
  PRESCRIPTION_DELETE: 'PRESCRIPTION_DELETE',
  PRESCRIPTION_WRITE: 'PRESCRIPTION_WRITE',
  PRESCRIPTION_CREATE: 'PRESCRIPTION_CREATE',
  PRESCRIPTION_VIEW: 'PRESCRIPTION_VIEW',
  PRESCRIPTION_CANCEL: 'PRESCRIPTION_CANCEL',
  PRESCRIPTION_DISPENSE: 'PRESCRIPTION_DISPENSE',
  REPORTS_MENU: 'REPORTS_MENU',
  ADMIN_MENU: 'ADMIN_MENU',
  USERS_VIEW: 'USER_VIEW',

  LAB_ADMIN: 'LAB_ADMIN',
  ROOM_ADMIN: 'ROOM_ADMIN',
  USER_ADMIN: 'USER_ADMIN',
  EHACARE_ADMIN: 'EHACARE_ADMIN',

  EHR_VIEW: 'EHR_ROLE_VIEW',
  EHR_READ: 'EHR_ROLE_READ',
  EHR_WRITE: 'EHR_ROLE_WRITE_EHR',
  EHR_QUERY: 'EHR_ROLE_QUERY',

  DEMOGRAPHICS_SEARCH: 'DEMOGRAPHICS_SEARCH',
  DEMOGRAPHICS_READ: 'DEMOGRAPHICS_READ',
  DEMOGRAPHICS_EXTENDED_OPERATION: 'DEMOGRAPHICS_EXTENDED_OPERATION',
  DEMOGRAPHICS_WRITE: 'DEMOGRAPHICS_WRITE',

  CAN_CLEAR_NOTIFICATIONS: 'CAN_CLEAR_NOTIFICATIONS',
  ADMISSION_CREATE: 'ADMISSION_CREATE',
  ENCOUNTER_COMPLETE: 'ENCOUNTER_COMPLETE',
  ADMISSION_VIEW: 'ADMISSION_VIEW',
  DOCTOR_ASSESSMENT_VIEW: 'DOCTOR_ASSESSMENT_VIEW',
  PATIENT_VIEW_LIST: 'PATIENT_VIEW_LIST',
  ENCOUNTER_VIEW_LIST: 'ENCOUNTER_VIEW_LIST',
  EHA_QUALITY_APP: 'EHA_QUALITY_APP',
  BMGF_LLM_MO: 'BMGF_LLM_MO',
  BMGF_LLM_CHEW: 'BMGF_LLM_CHEW',
  EHACare_LAB_: 'EHACare_LAB_',

  EHACare_view_own_encounter: 'EHACare_view_own_encounter',
  EHACare_view_own_prescription: 'EHACare_view_own_prescription',
  EHACare_view_own_labtest: 'EHACare_view_own_labtest',

  IDOVEN_VIEW: 'IDOVEN_VIEW'
};

export default permissions;
