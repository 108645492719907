import React from 'react';
import moment from 'moment';
import { RankingInfo, rankItem } from '@tanstack/match-sorter-utils';
import jwt_decode from 'jwt-decode';
import { DecodedToken } from '@pages/settings/ambulatory-care/user-admin/users';
// import { DecodedToken } from '@pages/admin';

export const currentDateTimeInUtc = () =>
  moment().toISOString().replace('Z', '').replace('T', ' ');

export const getUniqueId = () => {
  return new Date().valueOf().toString().slice(7);
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getCurrentDate = () => {
  return moment(new Date()).format('MMM D Y');
};

export const addHoursToDateTime = (date: Date, h: number) => {
  date.setHours(date.getHours() + h);
  return date;
};

export const getDateTimeFromISOString = (dateString: string) => {
  const time = dateString.substring(11, 16);
  const date = new Date(dateString);
  const year = date.getFullYear();
  let month: number | string = date.getMonth() + 1;
  let day: number | string = date.getDate();

  if (day < 10) {
    day = '0' + day;
  }
  if (month < 10) {
    month = '0' + month;
  }
  return `${day}/${month}/${year} ${time}`;
};

export function isValidDate(dateString: string) {
  var regEx = /^\d{4}-\d{2}-\d{2}$/;
  if (!dateString.match(regEx)) return false; // Invalid format
  var d = new Date(dateString);
  var dNum = d.getTime();
  if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
  return d.toISOString().slice(0, 10) === dateString;
}

export const getUserLocalDateTime = (UTCDate: string, format: string) => {
  const timeOffset = new Date().getTimezoneOffset();
  if (timeOffset < 0) {
    return moment(UTCDate).add(Math.abs(timeOffset), 'minutes').format(format);
  }
  return moment(UTCDate).subtract(timeOffset, 'minutes').format(format);
};

export const createFormParams = (params: { [key: string]: string }) => {
  return Object.keys(params)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`,
    )
    .join('&');
};

export const debounce = (inputFunction: {
  (event: React.ChangeEvent<HTMLInputElement>): void;
  apply?: any;
}) => {
  let timer: NodeJS.Timeout | null;
  return (...args: any) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      inputFunction.apply(this, args);
    }, 500);
  };
};

export const tableSearchFilter = (
  row: { getValue: (arg0: string) => unknown },
  columnId: string,
  value: string,
  addMeta: (arg0: { itemRank: RankingInfo }) => void,
) => {
  // Rank the Item
  const itemRank = rankItem(row.getValue(columnId), value);
  //store the item Rank info
  addMeta({ itemRank });

  //Return if the Item should be filtered or not
  return itemRank.passed;
};

export const mergeDuplicateCompositions = (
  result: any[],
  isAllergy = false,
) => {
  isAllergy &&
    result.map((item: any) => {
      item.Manifestation.value = [item.Manifestation.value];
    });

  const duplicateCompositionIds = result
    .map((item: any) => item.Composition_uid)
    .filter((v: string, i: number, vIds: string[]) => vIds.indexOf(v) != i);
  const duplicates = result.filter((item: any) =>
    duplicateCompositionIds.includes(item.Composition_uid),
  );
  const nonDuplicates = result.filter(
    (item: any) => !duplicateCompositionIds.includes(item.Composition_uid),
  );

  if (duplicates.length > 0) {
    duplicateCompositionIds.map((id: string) => {
      const miniDuplicates = duplicates.filter(
        (item: any) => item.Composition_uid === id,
      );
      const merged = miniDuplicates[0];
      if (isAllergy) {
        const manifestations: string[] = [];
        miniDuplicates.map((item: any) => {
          manifestations.push(item.Manifestation.value[0]);
        });
        merged.Manifestation.value = manifestations;
      }
      nonDuplicates.push(merged);
    });
    result = nonDuplicates;
  }
  return result;
};

export const hasPermission = (permission: string, session: any) => {
  const token = session?.accessToken;
  const decodedToken: DecodedToken = jwt_decode(token);
  const { roles } = decodedToken.resource_access.ehacare;
  return roles.includes(permission);
};

export const saveOriginPathToStorage = (path: string) => {
  const stringPath = JSON.stringify(path);
  localStorage.setItem('originPath', stringPath);
};

export const getOriginPathFromStorage = () => {
  let stringPath;
  if (localStorage.getItem('originPath')) {
    stringPath = JSON.parse(localStorage.getItem('originPath') as string);
  }
  return stringPath;
};

export const deleteOriginPathFromStorage = () => {
  localStorage.removeItem('originPath');
};

export const readFile = (
  file: File | null,
  type: 'text' | 'dataURL' | 'arrayBuffer',
) => {
  return new Promise<string | ArrayBuffer>((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.addEventListener('load', (e) => {
      if (e.target?.result && file !== null) {
        resolve(e.target.result);
      }
    });
    fileReader.addEventListener('error', () => {
      reject(new Error('Error reading file'));
    });
    if (file !== null) {
      if (type === 'text') {
        fileReader.readAsText(file);
      } else if (type === 'dataURL') {
        fileReader.readAsDataURL(file);
      } else if (type === 'arrayBuffer') {
        fileReader.readAsArrayBuffer(file);
      }
    }
  });
};

export const cleanUpCompositionId = (compositionId: string) => {
  return compositionId.split(':')[0];
};

export function setBetterCareFormField(ref: any, path: string, value: string) {
  const api = ref.current?.getScriptApi();
  api.setFieldValue(path, value);
}

export function generateRandomString(length: number) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters[randomIndex];
  }
  return result;
}
